import "../../styles/footer.scss"

import { Link } from "gatsby"
import React from "react"

import { footerLinks } from "../../constants/links"
import { Media } from "../../media"
import FooterMobil from "./FooterMobil"
import SocialLink from "./SocialLink"
import Supporter from "./Supporter"

const Footer = ({ frontpage = false, cols = 40, rows = 5, kontakt }) => {
  if (frontpage) {
    cols = 30
    rows = 5
  }
  const createGrid = (c, r) => {
    return new Array(c * r).fill(0).map((element, index) => {
      return <div key={index}></div>
    })
  }

  const classList = "footergrid cols-" + cols

  if (!kontakt) {
    return (
      <>
        <footer className="Footer footer_wrapper">
          <Media greaterThanOrEqual="lg">
            <div className="Columns_Three">
              <div className="Adress">
                <div>0371 28269115</div>
                <div>reichenhainer str.1</div>
                <div>09111 chemnitz</div>
              </div>
              <div className="Social_Media_wrapper">
                <SocialLink className="facebook" socialmedia="facebook" />
                <SocialLink className="instagram" socialmedia="instagram" />
              </div>
              <div className="Legal_Links">
                {footerLinks.map(flink => {
                  return (
                    <Link key={flink.id} to={flink.url}>
                      {flink.text}
                    </Link>
                  )
                })}
              </div>
            </div>
            <div className="Centered">
              <Supporter />
            </div>
          </Media>
          <Media lessThan="lg">
            <FooterMobil />
          </Media>
          {frontpage && (
            <div className={"footer_grid_grid " + classList}>
              {createGrid(cols, rows)}
            </div>
          )}
        </footer>
        {!frontpage && (
          <div className="footer_grid_wrapper">
            <div className={"footer_grid_grid " + classList}>
              {createGrid(cols, rows)}
            </div>
          </div>
        )}
      </>
    )
  } else {
    return (
      <footer className="footer_grid_wrapper">
        <div className={"footer_grid_grid " + classList}>
          {createGrid(cols, rows)}
        </div>
      </footer>
    )
  }
}

export default Footer
