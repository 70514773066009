import React from "react"
import { FaFacebookF, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa"
import { RiSoundcloudFill } from "react-icons/ri"

export const iconList = {
  facebook: {
    element: <FaFacebookF />,
    url:
      process.env.GATSBY_SITE === "transit"
        ? "https://www.facebook.com/transit.chemnitz"
        : "https://www.facebook.com/kulturbahnhofchemnitz",
  },
  instagram: {
    element: <FaInstagram />,
    url:
      process.env.GATSBY_SITE === "transit"
        ? "https://www.instagram.com/transit.club/"
        : "https://www.instagram.com/kulturbahnhof.chemnitz/",
  },
  soundcloud: {
    element: <RiSoundcloudFill />,
    url: "https://soundcloud.com/radio_transit",
  },
  twitter: { element: <FaTwitter />, url: "https://twitter.com" },
  whatsapp: { element: <FaWhatsapp />, url: "https://whatsapp.com" },
}
