import React from "react"

import { iconList } from "../../constants/socialmedia"

const SocialLinks = ({ className = "", socialmedia = "facebook" }) => {
  const data = iconList[socialmedia]
  if (!data) {
    return
  }
  return (
    <a href={data.url} target="_blank" rel="noreferrer">
      <div className={"Social_Media " + className}>{data.element}</div>
    </a>
  )
}
export default SocialLinks
