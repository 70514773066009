import React, { useState } from "react"
import { Link } from "gatsby"

import { topLinks, dropdown } from "../constants/links"

import transit_logo from "../images/transit.svg"
import transit_logo_secundary from "../images/transit_secundary.svg"

import kulturbahnhof_logo from "../images/kulturbahnhof.svg"
import kulturbahnhof_logo_secundary from "../images/kulturbahnhof_secundary.svg"

import "../styles/navbar.scss"

const Navbar = ({ location }) => {
  const [active, setActive] = useState(false)
  const toggleMenu = () => {
    setActive(!active)
  }
  return (
    <>
      <div className="overlay_wrapper">
        <div className={active ? "navbar_overlay active" : "navbar_overlay"}>
          <div className="navbar">
            <div className="logo logosize">
              {process.env.GATSBY_SITE === "transit" ? (
                <img src={transit_logo_secundary} alt="Transit" />
              ) : (
                <img src={kulturbahnhof_logo_secundary} alt="Kulturbahnhof" />
              )}
            </div>
            <div className="close_button">
              <button className="overlay_closeButton" onClick={toggleMenu}>
                <div className="close"></div>
              </button>
            </div>
            <div className="nav_strip">
              <div className="label">menu</div>
            </div>
          </div>
          <div className="menu">
            <div className="flex">
              <div className="main">
                {topLinks.map(link => {
                  return (
                    <Link
                      onClick={toggleMenu}
                      to={link.url}
                      className="nav-link"
                      key={link.id}
                      target={link?.external ? "_blank" : "_self"}
                    >
                      {link.text}
                    </Link>
                  )
                })}
              </div>
              <div className="secundary">
                <div className="col-1">
                  {dropdown[0].map(link => {
                    if (link.int) {
                      return (
                        <Link
                          onClick={toggleMenu}
                          to={link.url}
                          className="nav-link"
                          key={link.id}
                          target={link?.external ? "_blank" : "_self"}
                        >
                          {link.text}
                        </Link>
                      )
                    } else {
                      return (
                        <a
                          onClick={toggleMenu}
                          href={link.url}
                          className="nav-link"
                          key={link.id}
                          target={link?.external ? "_blank" : "_self"}
                          rel={link?.external ? "noreferrer" : ""}
                        >
                          {link.text}
                        </a>
                      )
                    }
                  })}
                </div>
                <div className="col-2">
                  {dropdown[1].map(link => {
                    if (link.int) {
                      return (
                        <Link
                          onClick={toggleMenu}
                          to={link.url}
                          className="nav-link"
                          key={link.id}
                          target={link?.external ? "_blank" : "_self"}
                        >
                          {link.text}
                        </Link>
                      )
                    } else {
                      return (
                        <a
                          onClick={toggleMenu}
                          href={link.url}
                          className="nav-link"
                          key={link.id}
                          target={link?.external ? "_blank" : "_self"}
                          rel={link?.external ? "noreferrer" : ""}
                        >
                          {link.text}
                        </a>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="navbar_wrapper Header_wrapper">
        <nav className="navbar">
          {process.env.GATSBY_SITE === "transit" ? (
            <div className="logo logosize">
              {location.pathname === "/" ? (
                <button
                  className="nobutton"
                  onClick={() =>
                    window[`scrollTo`]({ top: 0, behavior: `smooth` })
                  }
                >
                  <img src={transit_logo} alt="Transit" />
                </button>
              ) : (
                <Link to="/">
                  <img src={transit_logo} alt="Transit" />
                </Link>
              )}
            </div>
          ) : (
            <div className="logo logosize">
              {location.pathname === "/" ? (
                <button
                  className="nobutton"
                  onClick={() =>
                    window[`scrollTo`]({ top: 0, behavior: `smooth` })
                  }
                >
                  <img src={kulturbahnhof_logo} alt="Kulturbahnhof" />
                </button>
              ) : (
                <Link to="/">
                  <img src={kulturbahnhof_logo} alt="Kulturbahnhof" />
                </Link>
              )}
            </div>
          )}
          <div className="nav_button">
            <div
              tabIndex="0"
              role="button"
              onClick={toggleMenu}
              onKeyDown={toggleMenu}
              className="menu_toggle"
            >
              <div className="menuLines">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="nav_strip">
            {topLinks.map(link => {
              let splitLink = link.url.split(/#/)
              return (
                <Link
                  to={link.url}
                  className={
                    splitLink[0] === location.pathname
                      ? "nav-link active"
                      : "nav-link"
                  }
                  data-text={link.text}
                  key={link.id}
                >
                  {link.text}
                </Link>
              )
            })}
          </div>
        </nav>
      </div>
    </>
  )
}

export default Navbar
