import "../../styles/supporter.scss"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { useCollapse } from "react-collapsed"

const Supporter = () => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const {
    allStrapiSupporter: { nodes: supporter },
  } = useStaticQuery(query)

  const filteredSupporter = supporter.filter(sup =>
    sup.projects.find(p => p.short_title === process.env.GATSBY_SITE)
  )

  return (
    <div className="supporter_block_wrapper supporter_link">
      <div className="titleline">mit herzlicher unterstützung von</div>
      <div
        className="supporter_expansion"
        id="collapsible"
        {...getCollapseProps()}
      >
        <div className="supporter_grid">
          {filteredSupporter.map(sup => {
            if (process.env.GATSBY_SITE === "transit" && sup.image_darkbg) {
              return (
                <div className="supporter" key={sup.name}>
                  <a href={sup.link} target="_blank" rel="noopener noreferrer">
                    {sup.image_darkbg.localFile.childImageSharp ? (
                      <GatsbyImage
                        image={getImage(sup.image_darkbg.localFile)}
                        alt={sup.name}
                        objectFit="cover"
                        layout="constrained"
                      />
                    ) : (
                      <img
                        src={sup.image_darkbg.localFile.publicURL}
                        alt={sup.name}
                      />
                    )}
                  </a>
                </div>
              )
            } else if (
              process.env.GATSBY_SITE !== "transit" &&
              sup.image_lightbg
            ) {
              return (
                <div className="supporter" key={sup.name}>
                  <a href={sup.link} target="_blank" rel="noopener noreferrer">
                    {sup.image_lightbg.localFile.childImageSharp ? (
                      <GatsbyImage
                        image={getImage(sup.image_lightbg.localFile)}
                        alt={sup.name}
                        objectFit="cover"
                        layout="constrained"
                      />
                    ) : (
                      <img
                        src={sup.image_lightbg.localFile.publicURL}
                        alt={sup.name}
                      />
                    )}
                  </a>
                </div>
              )
            }
            return null
          })}
        </div>
      </div>
      <button className="collapsible_button" {...getToggleProps()}>
        <div className={isExpanded ? "plusicon minus" : "plusicon"}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="subtitle">
          {isExpanded ? "verstecke" : "zeige"} supporter
        </div>
      </button>
    </div>
  )
}

const query = graphql`
  {
    allStrapiSupporter {
      nodes {
        name
        image_darkbg {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
            publicURL
          }
        }
        image_lightbg {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
            publicURL
          }
        }
        link
        projects {
          short_title
        }
      }
    }
  }
`

export default Supporter
