import "../../styles/footerMobile.scss"

import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import React from "react"
import { useCollapse } from "react-collapsed"
import { VscAdd, VscArrowRight, VscRemove } from "react-icons/vsc"

import { footerLinks } from "../../constants/links"
import SocialLink from "./SocialLink"

const FooterMobil = () => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  const {
    allStrapiSupporter: { nodes: supporter },
  } = useStaticQuery(query)

  return (
    <div className="footer_mobile_block_wrapper Footer">
      <div className="Centered">
        <div className="Adress">
          <div>0371 28269115</div>
          <div>reichenhainer str.1</div>
          <div>09111 chemnitz</div>
        </div>
        <div className="Social_Media_wrapper">
          <SocialLink className="facebook" socialmedia="facebook" />
          <SocialLink className="instagram" socialmedia="instagram" />
        </div>
        <div className="expandables">
          <div className="item expand">
            <button className="collapsible_button text " {...getToggleProps()}>
              <div className="inner">
                supporter:innen
                <div className="icon">
                  {isExpanded ? <VscRemove /> : <VscAdd />}
                </div>
              </div>
            </button>
            <div id="collapsible" {...getCollapseProps()}>
              <div className="content">
                {supporter.map(sup => {
                  return (
                    <div className="supporter" key={sup.name}>
                      <a
                        href={sup.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {sup.name}
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          {footerLinks.map(flink => {
            return (
              <div className="item link" key={flink.id}>
                <Link className="text" to={flink.url}>
                  <div className="inner">
                    {flink.text}
                    <div className="icon">
                      <VscArrowRight />
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const query = graphql`
  {
    allStrapiSupporter(
      filter: { projects: { elemMatch: { short_title: { eq: "transit" } } } }
    ) {
      nodes {
        name
        image_lightbg {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        link
      }
    }
  }
`

export default FooterMobil
