export const topLinks =
  process.env.GATSBY_SITE === "transit"
    ? [
        {
          id: 1,
          text: "about",
          url: "/",
        },
        {
          id: 2,
          text: "programm",
          url: "/events",
        },
        {
          id: 3,
          text: "kontakt",
          url: "/kontakt",
        },
      ]
    : [
        {
          id: 1,
          text: "about",
          url: "/",
        },
        {
          id: 2,
          text: "programm",
          url: "/events/",
        },
        {
          id: 3,
          text: "projekte",
          url: "/#projects",
        },
        {
          id: 4,
          text: "kontakt",
          url: "/kontakt",
        },
      ]

export const dropdown =
  process.env.GATSBY_SITE === "transit"
    ? [
        [
          {
            id: 1,
            int: true,
            text: "archiv",
            url: "/events-archive",
          },
          {
            id: 2,
            int: true,
            text: "impressum",
            url: "/impressum",
          },
          {
            id: 3,
            int: true,
            text: "datenschutz",
            url: "/datenschutz",
          },
        ],
        [
          {
            id: 11,
            int: false,
            text: "facebook",
            url: "https://www.facebook.com/transit.chemnitz",
            external: true,
          },
          {
            id: 12,
            int: false,
            text: "instagram",
            url: "https://www.instagram.com/transit.club/",
            external: true,
          },
          {
            id: 13,
            int: false,
            text: "soundcloud",
            url: "https://soundcloud.com/radio_transit",
            external: true,
          },
        ],
      ]
    : [
        [
          {
            id: 1,
            int: true,
            text: "archiv",
            url: "/events-archive",
          },
          {
            id: 2,
            int: true,
            text: "impressum",
            url: "/impressum",
          },
          {
            id: 3,
            int: true,
            text: "datenschutz",
            url: "/datenschutz",
          },
        ],
        [
          {
            id: 11,
            int: false,
            text: "facebook",
            url: "https://www.facebook.com/kulturbahnhofchemnitz",
            external: true,
          },
          {
            id: 12,
            int: false,
            text: "instagram",
            url: "https://www.instagram.com/kulturbahnhof.chemnitz/",
            external: true,
          },
        ],
      ]

export const footerLinks = [
  {
    id: 1,
    text: "kontakt",
    url: "/kontakt",
  },
  {
    id: 2,
    text: "datenschutz",
    url: "/datenschutz",
  },
  {
    id: 3,
    text: "impressum",
    url: "/impressum",
  },
]
